// ==================================================
//  メインビジュアル（.mainvisual）
// ==================================================
// スライダー
document.addEventListener("DOMContentLoaded", function () {
  new Splide(".js-splide-mainvisual", {
    arrows: false,
    pagination: false,
    autoplay: true,
    type: "fade",
    rewind: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    updateOnMove: true,
    interval: 4000,
    speed: 2000,
  }).mount();
});

// ==================================================
//  ギャラリー（.gallery）
// ==================================================
// スライダー
document.addEventListener("DOMContentLoaded", function () {
  new Splide(".js-splide-gallery", {
    type: "loop",
    arrows: false,
    pagination: false,
    autoScroll: {
      speed: 0.5,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    autoWidth: true,
    autoHeight: true,
    drag: false,
    breakpoints: {
      600: {
        autoScroll: {
          speed: 0.3,
        },
      },
    },
  }).mount(window.splide.Extensions);
});
